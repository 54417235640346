import React from 'react';
import '../../styles/stars.css'

const Stars = () => {
    return (
        <section class="animated-background">
          <div id="stars1"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
        </section>
    )
}

export default Stars;